import { observable } from '@datagrid/state';

import type { BackendTypes, CSTypes } from '@tf/api';

import type { AppState } from '@/core/types';

export type AppStoreType = {
	state: AppState;
	tenant: string;
	currency: string;
	defs: CSTypes.GetFrontendIrResponse;
	processingScripts: Record<BackendTypes.SegmentKind, string[]>;
};

export const appStore = observable<AppStoreType>({
	state: 'loading',
	tenant: '',
	currency: '',
	defs: {
		structs: {},
		selfSegments: {},
		forms: [],
		entities: {},
		options: {},
		paletteItems: [],
	},
	processingScripts: {},
});
